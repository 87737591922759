<template>
  <div>
    <Schedule target="_blank" :path="'/' + $route.params.host + '/event/'" />
  </div>
</template>
  
<script>
  import Schedule from '@/shared/components/Schedule';
  
  export default {
    metaInfo: {
        title: 'Programma',
    },
    components: {
      Schedule,
    },
    data() {
        return {}
    },
    computed: {},
    methods: {},
  }
</script>